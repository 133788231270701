import {
  actiiveTourGuideConst,
  activeCompConst,
} from "new-dw-ui/src/new-ui/components/tourGuide/dashboard/utils/constants";
import { leftSidebarIdConstant } from "../new-dw-ui/src/new-ui/components/workspace/layout/leftside/leftsidebar/utils/constant";
import { lspTypes } from "../new-dw-ui/src/new-ui/components/workspace/layout/leftside/leftsidepanel/utils/constants";
import {
  initilRXState,
  lspSearchTypeInitState,
  lspSelectedInitState,
} from "../new-dw-ui/src/new-ui/components/workspace/utils/constants";
import { ShapesDrawingPencilModeType } from "pages/private/dashboard/workspace/Filters/ShapesFilters/utils/ShapeFiltersConstant";
// import { gsap } from "@wbm-npm/animated-canvas";

const initialState = {
  canvasExists: false,
  isListUpdate: false,
  isPopupClosed: false,
  canvas: null,
  toSave: false,
  compositionId: "",
  title: "Untitled",
  myDesignCount: 0,
  userAccDetail: { currentPass: "", newPass: "", promoCode: "" },
  isProfileUpdated: false,
  isCanvasLoadData: { type: "", status: false },
  transactionData: {},
  showChangeUploadPopUp: false,
  logout: "loggedIn",
  isCrop: false,
  cropObj: {},
  user: "",
  selectedTab: "template",
  currentCategory: {},
  uploadedItem: {},
  fonts: [],
  grid: { rows: 3, columns: 3 },
  resizeTitleId: 13,
  newResizeTitleId: -1,
  categoryWidth: 1920,
  categoryHeight: 1080,
  newCategoryWidth: 2550,
  newCategoryHeight: 1650,
  categoryName: "Videos",
  newCategoryName: "Images",
  refreshListView: false,
  refreshGridView: false,
  templateId: "",
  listViewChecked: [],
  itemPurchased: false,
  colorPickerActive: false,
  changingBgColor: "",
  gridShow: false,
  editor: "video",
  newEditor: "image",
  isRunAdminSaveTemp: null,
  isSpegtComp: false,
  templateCategoryData: [],
  ownerSearch: { is: false, value: "" },
  recommendedOwners: [],
  homePageJobsCount: { type: false, count: 0 },
  isDragging: false,
  listViewSizeSearch: {
    searchValue: "",
    selectedSizeId: false,
    sizeAnchor: null,
    deSelectAll: false,
  },
  videoList: [],
  assetAdded: {},
  canvasResizeFlag: false,
  tempVidRescaleLayer: [],
  isPaid: "none",
  paymentData: {},
  selectedTextBoxProps: { bgColor: "" },
  copyObj: {},
  npspopupstatus: { open: false, required: false, isNew: true },
  homePageMenuName: "My Designs",
  drawingModeType: ShapesDrawingPencilModeType.Pencil,
  maskingText: "Enable Masking",
  objectType: "",
  outlineValue: 0,
  dragOnCanvasData: {},
  textOnSave: "save",
  textToBeCurved: " ",
  updateLayerAction: false,
  setObjectLayers: [],
  otherColorPicker: { type: "", status: false, selectedColor: "" },
  isCanvasLoader: false,
  wsAllType: {
    filterType: lspTypes,
    status: false,
  },
  wsIsCanvasEventsTrigger: false,
  colorPickerObj: {
    fillClr: "",
    borderClr: "",
  },
  toolbarLeftsidePanelRXState: initilRXState,
  leftSideFilterImage: "",
  objectClrType: "",
  objectColor: {
    fill: "",
    stroke: "",
    background: "",
  },
  lspSelected: lspSelectedInitState,
  lspSearch: lspSearchTypeInitState,
  lspInfinityScrollRefresh: null,
  useApiIsLoading: false,
  activeToolbar: {
    name: "",
    id: 0,
  },
  canvasBackground: "",
  previewColor: "",
  previousColor: "",
  selectedMenu: leftSidebarIdConstant.text,
  brandingFontList: [],
  brandingColorList: [],
  brandingAssetList: [],
  duration: 12,
  videoPresentOverCanvas: false,
  isLayerPanelVisible: false,
  colorPanelBack: "",
  checkListArr: [],
  updateList: false,
  selectedColorSvg: {
    ind: null,
    colorId: "",
    newSlctClr: "",
  },
  isArtBoardOpen: false,
  animateProperties: {
    animate: "both",
    direction: "top",
    speed: 1,
    duration: 1,
    type: "",
  },
  tl: null,
  dwfonts: [],
  canvasThumb: { thumb: "", thumbFor: "" },
  animationMode: "EditMode",
  userJourney: {},
  isAccountMenuOpen: false,
  updateHomeFolderList: false,
  customSizeValues: {
    width: null,
    height: null,
    dpi: null,
    displayWidth: null,
    displayHeight: null,
  },
  newCustomSizeValues: {
    width: null,
    height: null,
    dpi: null,
    displayWidth: null,
    displayHeight: null,
  },
  hideHdResizeModal: false,
  updatedJsonObj: {},
  clippingHeightValue: null,
  clippingWidthValue: null,
  activatedArtboardId: null,
  brightnessValue: null,
  saturationValue: null,
  contrastvalue: null,
  tintValue: null,
  hueValue: null,
  blurValue: null,
  opacityValue: null,
  currentWidth: 1200,
  currentHeight: 900,
  lspMasonryRendering: false,
  initMasonaryRendered: false,
  initUpdatedJson: null,
  isStoryVideoComposition: false,
  openPremiumTempModal: false,
  openUpgradePopup: false,
  premiumTempId: null,
  initRedirectElement: false,
  isReloadingCanvas: false,
  isPremiumTempOpen: false,
  initRenderedArtboard: [1],
  composerObj: null,
  isLoadingObjOnCanvas: false,
  canvasHiddenSpacingRdxState: 300,
  initialLockedLayers: {},
  isEditModeLoading: false,
  singleLspCarouselRefresh: null,
  availableFonts: [],
  gridLineColor: "purple",
  loadedFonts: [],
  isCanvasResizing: false,  
  isSearchingSimilarAssets: {
    isSearching: false,
    wbmId: ''
  },
  profileBase64: "",
  textEffectMenu: "",
  myLoadedFonts: [],
  totalFavCount: 0,
};

const rootReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setCanvasExists":
      return { ...state, ...rest };
    case "updateList":
      return { ...state, ...rest };
    case "closePopup":
      return { ...state, ...rest };
    case "storeCanvas":
      return { ...state, ...rest };
    case "toSave":
      return { ...state, ...rest };
    case "composition":
      return { ...state, ...rest };
    case "setTitle":
      return { ...state, ...rest };
    case "setCount":
      return { ...state, ...rest };
    case "setUserAccData":
      return { ...state, ...rest };
    case "updateProfile":
      return { ...state, ...rest };
    case "isCanvasLoad":
      return { ...state, ...rest };
    case "saveTransaction":
      return { ...state, ...rest };
    case "setChangeUploadPopup":
      return { ...state, ...rest };
    case "logout":
      return { ...state, ...rest };
    case "isCrop":
      return { ...state, ...rest };
    case "updateCropObj":
      return { ...state, ...rest };
    case "userType":
      return { ...state, ...rest };
    case "selectedTab":
      return { ...state, ...rest };
    case "currentCategory":
      return { ...state, ...rest };
    case "uploadedItem":
      return { ...state, ...rest };
    case "updateFonts":
      return { ...state, ...rest };
    case "GridRowsColumn":
      return { ...state, ...rest };
    case "setResizeId":
      return { ...state, ...rest };
    case "setNewResizeId":
      return { ...state, ...rest };
    case "setRefreshListView":
      return { ...state, ...rest };
    case "setRefreshGridView":
      return { ...state, ...rest };
    case "setTemplateId":
      return { ...state, ...rest };
    case "setCanvasWidth":
      return { ...state, ...rest };
    case "setCanvasHeight":
      return { ...state, ...rest };
    case "setCanvasName":
      return { ...state, ...rest };
    case "setNewCanvasName":
      return { ...state, ...rest };
    case "fetchCheckedArray":
      return { ...state, ...rest };
    case "purchaseRefresh":
      return { ...state, ...rest };
    case "updateColorPicker":
      return { ...state, ...rest };
    case "updateChangeColor":
      return { ...state, ...rest };
    case "gridShowing":
      return { ...state, ...rest };
    case "updateEditor":
      return { ...state, ...rest };
    case "updateNewEditor":
      return { ...state, ...rest };
    case "updateAdminSaveTemp":
      return { ...state, ...rest };
    case "spegetiComp":
      return { ...state, ...rest };
    case "updateTemplateCategory":
      return { ...state, ...rest };
    case "updateOwnerSearch":
      return { ...state, ...rest };
    case "updateRecommendedOwners":
      return { ...state, ...rest };
    case "updateHomePageJobsCount":
      return { ...state, ...rest };
    case "dragStatus":
      return { ...state, ...rest };
    case "updateListViewSizeSearch":
      return { ...state, ...rest };
    case "deSelectListnGrid":
      return { ...state, ...rest };
    case "updateVidList":
      return { ...state, ...rest };
    case "assetAddedOnCanvas":
      return { ...state, ...rest };
    case "setCanvasResizeFlag":
      return { ...state, ...rest };
    case "setTempVidRescaleLayer":
      return { ...state, ...rest };
    case "setPaid":
      return { ...state, ...rest };
    case "setPaymentData":
      return { ...state, ...rest };
    case "setSelectedTextBoxProps":
      return { ...state, ...rest };
    case "setCopyObj":
      return { ...state, ...rest };
    case "updateNPSStatus":
      return { ...state, ...rest };
    case "setHomePageMenuName":
      return { ...state, ...rest };
    case "updateDrawingModeType":
      return { ...state, ...rest };
    case "setMaskingText":
      return { ...state, ...rest };
    case "setObjectType":
      return { ...state, ...rest };
    case "setOutline":
      return { ...state, ...rest };
    case "setDragOnCanvasData":
    case "setTextOnSave":
      return { ...state, ...rest };
    case "setTextToBeCurved":
    case "updateLayerAction":
      return { ...state, ...rest };
    case "setObjectLayers":
      return { ...state, ...rest };
    case "setOtherColorPicker":
      return { ...state, ...rest };
    case "isCanvasLoader":
      return { ...state, ...rest };
    case "setWsLspCollapse":
      return { ...state, ...rest };
    case "setWsIsCanvasEventsTrigger":
      return { ...state, ...rest };
    case "setColorObjFromPicker":
      return { ...state, ...rest };
    case "setToolbarLeftsidePanelRXState":
      return { ...state, ...rest };
    case "setFilterImgSrcRxState":
      return { ...state, ...rest };
    case "setObjectClrType":
      return { ...state, ...rest };
    case "setObjectClrs":
      return { ...state, ...rest };
    case "setLspSelected":
      return { ...state, ...rest };
    case "setLspSearch":
      return { ...state, ...rest };
    case "setLspInfinityScrollRefresh":
      return { ...state, ...rest };
    case "setUseApiLoading":
      return { ...state, ...rest };
    case "setActiveToolbarItem":
      return { ...state, ...rest };
    case "updateCanvasBackground":
      return { ...state, ...rest };
    case "setPreviewColor":
      return { ...state, ...rest };
    case "setPreviousColor":
      return { ...state, ...rest };
    case "setSelectedMenu":
      return { ...state, ...rest };
    case "setBrandingFonts":
      return { ...state, ...rest };
    case "setBrandColors":
      return { ...state, ...rest };
    case "setBrandingAssets":
      return { ...state, ...rest };
    case "setDuration":
      return { ...state, ...rest };
    case "setVideoPresentOverCanvas":
    case "setLayerPanelVisibility":
      return { ...state, ...rest };
    case "setColorPanelBack":
      return { ...state, ...rest };
    case "setCheckListArr":
      return { ...state, ...rest };
    case "setUpdatedList":
      return { ...state, ...rest };
    case "setSelectedColorSvg":
      return { ...state, ...rest };
    case "setIsArtboardOpen":
      return { ...state, ...rest };
    case "setAnimataProperties":
      return { ...state, ...rest };
    case "setTl":
      return { ...state, ...rest };
    case "dwFontsFamilies":
      return { ...state, ...rest };
    case "setCanvasThumb":
      return { ...state, ...rest };
    case "setAnimationMode":
      return { ...state, ...rest };
    case "setUserTourJourney":
      return { ...state, ...rest };
    case "setAccountMenu":
      return { ...state, ...rest };
    case "setUpdateHomeFolderList":
      return { ...state, ...rest };
    case "setCustomSizeValues":
      return { ...state, ...rest };
    case "setNewCustomSizeValues":
      return { ...state, ...rest };
    case "setNewCanvasWidth":
      return { ...state, ...rest };
    case "setNewCanvasHeight":
      return { ...state, ...rest };
    case "setHideHdResizeModal":
      return { ...state, ...rest };
    case "setUpdatedJsonObj":
      return { ...state, ...rest };
    case "setClippingHeightValue":
      return { ...state, ...rest };
    case "setClippingWidthValue":
      return { ...state, ...rest };
    case "setActivatedArtboardId":
      return { ...state, ...rest };
    case "setBrightnessValue":
      return { ...state, ...rest };
    case "setSaturationValue":
      return { ...state, ...rest };
    case "setContrastValue":
      return { ...state, ...rest };
    case "setTintValue":
      return { ...state, ...rest };
    case "setHueValue":
      return { ...state, ...rest };
    case "setBlurValue":
      return { ...state, ...rest };
    case "setOpacityValue":
      return { ...state, ...rest };
    case "setCurrentWidth":
      return { ...state, ...rest };
    case "setCurrentHeight":
      return { ...state, ...rest };
    case "setLspMasonryRendering":
      return { ...state, ...rest };
    case "setInitMasonaryRendered":
      return { ...state, ...rest };
    case "setInitUpdatedJson":
      return { ...state, ...rest };
    case "setIsStoryVideoComposition":
      return { ...state, ...rest };
    case "setOpenPremiumTempModal":
      return { ...state, ...rest };
    case "setOpenUpgradePopup":
      return { ...state, ...rest };
    case "setPremiumTempId":
      return { ...state, ...rest };
    case "setInitRedirectElement":
      return { ...state, ...rest };
    case "setIsReloadingCanvas":
      return { ...state, ...rest };
    case "setIsPremiumTempOpen":
      return { ...state, ...rest };
    case "setInitRenderedArtboard":
      return { ...state, ...rest };
    case "setComposerObj":
      return { ...state, ...rest };
    case "setIsLoadingObjOnCanvas":
      return { ...state, ...rest };
    case "setCanvasHiddenSpacingRdxState":
      return { ...state, ...rest };
    case "setInitialLockedLayers":
      return { ...state, ...rest };
    case "setIsEditModeLoading":
      return { ...state, ...rest };
    case "setSingleLspCarouselRefresh":
      return { ...state, ...rest };
    case "setAvailableFonts":
      return { ...state, ...rest };
    case "setGridLineColor":
      return { ...state, ...rest };
    case "setLoadedFonts":
      return { ...state, ...rest }; 
    case "setIsCanvasResizing":
      return { ...state, ...rest };
    case "setIsSearchingSimilarAssets":
      return { ...state, ...rest };
    case "setProfileBase64":
      return { ...state, ...rest };
    case "setTextEffectMenu":
      return { ...state, ...rest };
    case "setMyLoadedFonts":
      return { ...state, ...rest };
    case "setTotalFavCount":
      return { ...state, ...rest };
    default:
      return state;
  }
};

export default rootReducer;
